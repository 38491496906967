"use client";

import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export const HEADER_HORIZONTAL_MENU_ID = "header_horizontal_menu";

export const HEADER_VERTICAL_MENU_ID = "header_vertical_menu";

export function HeaderMenu({ children }: { children?: React.ReactNode }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  const horizontalMenuOutlet = document.getElementById(HEADER_HORIZONTAL_MENU_ID)!;
  const verticalMenuOutlet = document.getElementById(HEADER_VERTICAL_MENU_ID)!;

  return (
    <>
      {createPortal(children, horizontalMenuOutlet)}
      {createPortal(children, verticalMenuOutlet)}
    </>
  );
}
