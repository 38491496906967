"use client";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";

import ThemeSwitch from "@/components/theme-switch";

import { HEADER_HORIZONTAL_MENU_ID, HEADER_VERTICAL_MENU_ID } from "./header-content";

export default function Header({
  className = "",
  children = null,
  fluid = false,
  ...props
}: {
  children?: React.ReactNode;
  fluid?: boolean;
  [key: string]: any;
}): JSX.Element {
  const pathname = usePathname();

  return (
    <header className="daisy-navbar bg-base-300" data-theme="dark" {...props}>
      <div className={clsx("mx-auto flex flex-grow items-center", { container: !fluid })}>
        <div className="daisy-dropdown">
          <div tabIndex={0} role="button" className="daisy-btn daisy-btn-ghost lg:hidden">
            <FontAwesomeIcon icon={faBars} size="xl" />
          </div>
          <div tabIndex={0} id={HEADER_VERTICAL_MENU_ID} className="daisy-dropdown-content z-[1]"></div>
        </div>
        <Link href="/" className="daisy-btn daisy-btn-ghost mr-3 bg-transparent px-2 text-xl">
          <h1 className="daisy-indicator">
            <span className="daisy-badge daisy-indicator-item daisy-badge-warning daisy-badge-sm rotate-6">Alpha</span>
            SEM Analyzer
          </h1>
        </Link>
        <div id={HEADER_HORIZONTAL_MENU_ID} className="hidden flex-1 items-center lg:flex"></div>
        <div className="flex-1 lg:hidden"></div>
        <ThemeSwitch />
        <Link
          role="button"
          href={"/auth/login" + (pathname.startsWith("/auth") ? "" : "?to=" + pathname)}
          className={clsx("daisy-btn daisy-btn-neutral daisy-btn-sm ml-2", {
            "daisy-btn-disabled": pathname.startsWith("/auth/login"),
          })}
        >
          Log In
        </Link>
        <Link
          role="button"
          href="/auth/signup"
          className={clsx("daisy-btn daisy-btn-primary daisy-btn-sm ml-2", {
            "daisy-btn-disabled": pathname.startsWith("/auth/signup"),
          })}
        >
          Sign Up
        </Link>
      </div>
    </header>
  );
}
